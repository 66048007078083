var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            top: "7vh",
            modal: false,
            visible: _vm.Spilke,
            title: "创建新活动",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Spilke = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("h3", [_vm._v("基础信息")]),
              _c("el-divider"),
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动时间：", prop: "Tiem" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["12:00:00"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                            },
                            on: { change: _vm.handlerpicker },
                            model: {
                              value: _vm.form.Tiem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "Tiem", $$v)
                              },
                              expression: "form.Tiem",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择客户：", prop: "radio" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.radio,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "radio", $$v)
                                },
                                expression: "form.radio",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("全部客户"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: "1" } },
                                [
                                  _vm._v(" 限定业务员名下客户 "),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "15px" } },
                                    [_vm._v(_vm._s(_vm.user_data) + "人")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.form.radio == "1",
                                          expression: "form.radio == '1'",
                                        },
                                      ],
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleSet },
                                    },
                                    [_vm._v(" 设置 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注：", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { type: "textarea", rows: 2 },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [_c("h3", [_vm._v("活动规则")]), _c("el-divider")],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动商品：" } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                stripe: "",
                                data: _vm.tableData,
                                height: "300px",
                              },
                            },
                            [
                              _vm._l(_vm.colemd, function (list, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: list.label,
                                    align: list.align,
                                    prop: list.prop,
                                    width: list.width,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      list.label == "活动奖品"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var $index = ref.$index
                                              var row = ref.row
                                              return [
                                                _c("goods-search", {
                                                  attrs: {
                                                    "f-key": row.goods_name,
                                                  },
                                                  on: {
                                                    "add-rows": _vm.addRows,
                                                    "select-goods-all":
                                                      function ($event) {
                                                        return _vm.selectGoods(
                                                          $event,
                                                          row,
                                                          $index
                                                        )
                                                      },
                                                  },
                                                }),
                                              ]
                                            },
                                          }
                                        : list.label == "单位"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "70px",
                                                    },
                                                    attrs: {
                                                      placeholder: "选择单位",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.unitChange(
                                                          $event,
                                                          row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: row.unit_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "unit_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "row.unit_id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    row.arr_unit,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.unit_name,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          }
                                        : list.label == "活动价"
                                        ? {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  attrs: {
                                                    onkeyup:
                                                      "value=value.replace(/[^\\d.]/g,'') ",
                                                  },
                                                  model: {
                                                    value: row.price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "price",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.price",
                                                  },
                                                }),
                                              ]
                                            },
                                          }
                                        : {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(row[list.prop]) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  fixed: "right",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlerAdd(
                                                  row,
                                                  $index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 添加 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handlerDelet(
                                                  row,
                                                  $index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("set-component", {
                ref: "setComponent",
                on: { "set-userid": _vm.setUserId },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlersbumit },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.Spilke = !_vm.Spilke
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
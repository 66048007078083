<!--
 * @Author: your name
 * @Date: 2021-01-24 18:29:57
 * @LastEditTime: 2021-03-30 10:13:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\spikeDiscount\components\modify.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="Spilke" :title="title" center>
      <div>
        <h3>基础信息</h3>
        <el-divider></el-divider>
        <el-row>
          <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-form-item label="活动名称：" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入活动名称"
                style="width: 220px"
              />
            </el-form-item>
            <el-form-item label="活动时间：" prop="Tiem">
              <el-date-picker
                v-model="form.Tiem"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['12:00:00']"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                @change="handlerpicker"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="选择客户：" prop="radio">
              <el-radio-group v-model="form.radio">
                <el-radio label="0">全部客户</el-radio>
                <el-radio label="1">
                  限定业务员名下客户
                  <span style="margin-right: 15px">{{ user_data }}人</span>
                  <el-button
                    v-show="form.radio == '1'"
                    type="primary"
                    @click="handleSet"
                  >
                    设置
                  </el-button>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                :rows="2"
                style="width: 320px"
              />
            </el-form-item>
            <el-row>
              <h3>活动规则</h3>
              <el-divider></el-divider>
            </el-row>
            <el-form-item label="活动商品：">
              <el-table stripe :data="tableData" height="300px">
                <el-table-column
                  v-for="(list, index) in colemd"
                  :key="index"
                  :label="list.label"
                  :align="list.align"
                  :prop="list.prop"
                  width=""
                >
                  <template
                    v-if="list.label == '活动奖品'"
                    #default="{ $index, row }"
                  >
                    <goods-search
                      :f-key="row.goods_name"
                      @add-rows="addRows"
                      @select-goods-all="selectGoods($event, row, $index)"
                    ></goods-search>
                  </template>
                  <template v-else-if="list.label == '单位'" #default="{ row }">
                    <el-select
                      v-model="row.unit_id"
                      placeholder="选择单位"
                      style="width: 70px"
                      @change="unitChange($event, row)"
                    >
                      <el-option
                        v-for="item in row.arr_unit"
                        :key="item.id"
                        :label="item.unit_name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </template>
                  <template
                    v-else-if="list.label == '活动价'"
                    #default="{ row }"
                  >
                    <el-input
                      v-model="row.price"
                      style="width: 80px"
                      onkeyup="value=value.replace(/[^\d.]/g,'') "
                    />
                  </template>
                  <template v-else #default="{ row }">
                    {{ row[list.prop] }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  align="center"
                  fixed="right"
                  width="120"
                >
                  <template #default="{ row, $index }">
                    <el-button type="text" @click="handlerAdd(row, $index)">
                      添加
                    </el-button>
                    <el-button type="text" @click="handlerDelet(row, $index)">
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </el-row>
        <set-component
          ref="setComponent"
          @set-userid="setUserId"
        ></set-component>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-show="showbutton == 1"
          type="primary"
          @click="handlersbumit"
        >
          保 存
        </el-button>
        <el-button @click="Spilke = !Spilke">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import timestamp from '@/utils/timestamp'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import { postAction } from '@/api/Employee'
  import { text } from 'body-parser'
  import { dataStack } from 'echarts/lib/helper'
  export default {
    components: {
      GoodsSearch,
      SetComponent,
    },
    data() {
      return {
        Spilke: false,
        title: '编辑活动',
        showbutton: 1,
        url: {
          save: '/shopAdmin/discount/save',
          modify: '/shopAdmin/discount/info',
        },
        form: {
          id: '', //活动id，添加活动时不传
          name: '', //名称
          Tiem: [], //提交时需要删除出来
          radio: '0', //提交时需要删除出来
          desc: '', //说明
          start_time: '', //开始，时间戳
          end_time: '', //结束，时间戳
          limit_users: '', //选择客户，不指定为0 ，指定客户以逗号分隔客户id
          remark: '', //备注
          goods_data: '', //商品数据
        },
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          radio: [
            { required: true, message: '请选择客户要求', trigger: 'change' },
          ],
          remark: [
            { required: true, message: '请输入想写的备注', trigger: 'blur' },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          Tiem: [
            {
              required: true,
              message: '请选择日期',
              trigger: 'change',
            },
          ],
        },
        user_data: 0,
        tableData: [
          {
            arr_unit: [],
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
            price: '',
            sell_price: '',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '活动奖品',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '',
          },
          {
            order: 4,
            label: '销售价',
            align: 'center',
            prop: 'sell_price',
            width: '',
          },
          {
            order: 5,
            label: '活动价',
            align: 'center',
            prop: 'price',
            width: '',
          },
        ],
      }
    },
    watch: {
      Spilke(val) {
        if (!val) {
          this.title == '编辑活动'
          this.showbutton = 1
          this.$emit('getlist')
        }
      },
      'form.remark'(val) {
        this.form.desc = val
      },
    },
    methods: {
      handlerData(row) {
        var a = []
        postAction(this.url.modify, { id: row.id })
          .then((res) => {
            console.log(res)
            this.form.id = res.data.id
            this.form.name = res.data.name //名称
            // this.form.Tiem= '', //提交时需要删除出来
            this.form.radio = '0' //提交时需要删除出来
            // desc: '', //说明
            this.form.start_time = res.data.start_time //开始，时间戳
            this.form.end_time = res.data.end_time //结束，时间戳
            this.form.limit_users = res.data.limit_custs //选择客户，不指定为0 ，指定客户以逗号分隔客户id
            this.form.remark = res.data.desc //备注
            this.form.goods_data = '' //商品数据
            console.log(
              timestamp.timeFormat(res.data.start_time),
              timestamp.timeFormat(res.data.end_time)
            )
            var slotTime = new Array()
            slotTime.push(timestamp.timeFormat(res.data.start_time))
            slotTime.push(timestamp.timeFormat(res.data.end_time))
            this.form.Tiem = slotTime
            this.user_data = res.data.count_limit
            if (res.data.goods_data.length == 0) {
              this.tableData = [
                {
                  arr_unit: '',
                  goods_id: '',
                  goods_name: '',
                  goods_specs: '',
                  unit_id: '',
                  unit_name: '',
                  price: '',
                  sell_price: '',
                },
              ]
            } else {
              res.data.goods_data.forEach((item) => {
                item.units.forEach((list) => {
                  this.$set(list, 'unit_name', list.name)
                  delete list.name
                })
                var b = {
                  arr_unit: item.units,
                  goods_id: item.goods_id,
                  goods_name: item.goods_name,
                  goods_specs: item.goods_specs,
                  unit_id: item.unit_id,
                  unit_name: item.unit_name,
                  price: item.price,
                  sell_price: item.sell_price,
                }
                a.push(b)
              })
              this.tableData = a
            }

            console.log(this.form.Tiem, this.tableData)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlersbumit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var goods = this.tableData.filter((item) => {
              return item.goods_name != '' || item.goods_name != undefined
            })
            console.log(goods, this.tableData, '活动商品1')
            goods.forEach((list) => {
              delete list.arr_unit
            })
            console.log(goods, this.tableData, '活动商品2')
            try {
              if (goods.length <= 0) {
                this.$message({
                  message: '请选择至少一件活动商品',
                  type: 'info',
                })
              } else {
                if (this.form.radio == '1') {
                  this.$message({
                    message: '请选择至少一名业务员',
                    type: 'info',
                  })
                } else {
                  this.form.goods_data = JSON.stringify(goods)
                  const data = JSON.parse(JSON.stringify(this.form))
                  delete data.radio
                  delete data.Tiem
                  postAction(this.url.save, data)
                    .then((res) => {
                      console.log(res)
                      if (res.code == 200) {
                        this.$message({
                          message: res.msg,
                          type: 'success',
                        })
                        this.Spilke = false
                        this.$emit('getlist')
                      } else {
                        this.$message({
                          message: res.msg,
                          type: 'warning',
                        })
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }
              }
            } catch (error) {
              console.log(error)
              this.$message({
                message: '请选择至少一件活动商品',
                type: 'info',
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },

      handleSet() {
        if (this.form.radio == '1') {
          this.$refs.setComponent.showDialog = true
        } else {
          this.$message({
            message: '请先选择限定客户',
            type: 'warning',
          })
        }
      },
      handlerpicker(res) {
        console.log(res)
        this.form.start_time = res[0]
        this.form.end_time = res[1]
        this.form.start_time = timestamp.timestamp(this.form.start_time)
        this.form.end_time = timestamp.timestamp(this.form.end_time)
        console.log(this.form.end_time, this.form.start_time)
      },
      // 添加
      handlerAdd(row, index) {
        this.tableData.splice(index + 1, 0, JSON.parse(JSON.stringify(row)))
      },
      // 删除
      handlerDelet(row, index) {
        if (this.tableData.length <= 1) {
          this.$message({
            message: '不能在删除了呀',
            type: 'warning',
          })
        } else {
          this.tableData.splice(index, 1)
        }
      },
      selectGoods(event, row, index) {
        console.log(event, row, '1')
        var data = {
          arr_unit: event.arr_unit,
          goods_id: event.goods_id,
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.arr_unit[0].unit_id,
          unit_name: event.arr_unit[0].unit_name,
          price: event.arr_unit[0].sell_price,
          sell_price: event.arr_unit[0].sell_price,
        }
        // Object.assign(row, event)
        Object.assign(row, data)
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              goods_specs: item.specs,
              unit_id: item.arr_unit[0].unit_id,
              unit_name: item.arr_unit[0].unit_name,
              price: item.goods_price,
              sell_price: item.goods_price,
            }
            this.tableData.splice(this.tableData.length - 1, 0, a)
          })
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let id = row.arr_unit.filter((item) => item.id == e)[0].unit_id
        row.unit_id = id
        row.sell_price = price
        row.price = price
        row.unit_name = name
      },
      setUserId(val) {
        console.log(val, val.split(','))
        this.form.limit_users = val
        this.user_data = val.split(',').length
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
    },
  }
</script>

<style></style>

<!--
 * @Author: your name
 * @Date: 2021-01-19 15:41:45
 * @LastEditTime: 2021-03-26 16:33:34
 * @LastEditors: Please set LastEditors
 * @Description: 秒杀活动
 * @FilePath: \sd-vue-admin\src\views\project\marketing\activity\spikeDiscount\index.vue
-->
<template>
  <div style="padding: 20px">
    <el-row :gutter="20">
      <el-col :span="17">
        <el-form ref="form" :model="form" inline>
          <el-form-item prop="name">
            <el-input v-model="form.name" placeholder="活动名称" />
          </el-form-item>
          <el-form-item prop="status">
            <el-select v-model="form.status" style="width: 120px">
              <el-option
                v-for="item in status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="desc">
            <el-input v-model="form.desc" placeholder="备注" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3">
        <el-button @click="handlercreate">创建新活动</el-button>
      </el-col>
    </el-row>
    <!-- table表格 -->
    <el-row>
      <el-table stripe :data="tableData">
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '状态'" #default="{ row }">
            <span v-show="row.status == 1">开启</span>
            <span v-show="row.status == 0">终止</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template #default="{ row }">
            <el-button type="text" @click="handlermodify(row)">修改</el-button>

            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-popconfirm
              title="您真的要终止这个活动吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button
                v-show="row.status != 0"
                slot="reference"
                style="margin-left: 10px"
                type="text"
              >
                终止
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              title="您真的要删除这个活动吗？"
              @confirm="handlerdelete(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-spilke ref="spilke" @getlist="handlergetlist"></add-spilke>
    <modify ref="modify" @getlist="handlergetlist"></modify>
  </div>
</template>

<script>
  import modify from './components/modify.vue'
  import AddSpilke from './components/AddSpilke.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddSpilke,
      modify,
    },
    data() {
      return {
        form: {
          name: '', //活动名称
          desc: '', //备注
          status: '', //1未开始，2进行中，3已结束，4已终止
          pageNo: 1, //当前页
          pageSize: 10, //条数
        },
        tableData: [],
        total: 0,
        colemd: [
          {
            label: '活动名称',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '开始时间',
            align: 'center',
            prop: 'start_time',
            width: '',
          },
          {
            label: '结束时间',
            align: 'center',
            prop: 'end_time',
            width: '',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'status',
            width: '',
          },
          {
            label: '备注',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
        ],
        status: [
          {
            id: '1',
            name: '未开始',
          },
          {
            id: '2',
            name: '进行中',
          },
          {
            id: '3',
            name: '已结束',
          },
          {
            id: '4',
            name: '已终止',
          },
        ],
        url: {
          list: '/shopAdmin/discount/list',
          delete: '/shopAdmin/discount/delete',
          stop: '/shopAdmin/discount/stop',
        },
      }
    },
    mounted() {
      this.handlerInquire()
    },
    methods: {
      handlergetlist() {
        this.handlerInquire()
      },
      handlerlink(row) {
        this.$refs.modify.Spilke = true
        this.$refs.modify.handlerData(row)
        this.$refs.modify.showbutton = 2
        this.$refs.modify.title = '查看活动'
      },
      handlermodify(row) {
        this.$refs.modify.Spilke = true
        this.$refs.modify.handlerData(row)
      },
      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerdelete(row) {
        postAction(this.url.delete, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlercreate() {
        this.$refs.spilke.Spilke = true
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>

<style></style>

/*
 * @Author: your name
 * @Date: 2021-01-22 17:44:29
 * @LastEditTime: 2021-01-24 20:45:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\utils\timestamp.js
 */
export default {
  timestamp: function (date) {
    var oldTime = new Date(date).getTime() / 1000
    return oldTime
  },
  timeFormat(times) {
    console.log(times)
    //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
    var date = new Date(Number(times) * 1000)
    var Y = date.getFullYear() + '-'
    var M =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes() + ':'
    var s = date.getSeconds()
    return Y + M + D + h + m + s
  },
}
